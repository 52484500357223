import {
    Box,
    Button,
    Center,
    Container,
    FormControl,
    Image,
    Text,
    useToast,
    VStack,
} from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import 'yup-phone-lite';
import bg2 from '../../images/bg2.jpg';
import WhiteLogo from '../../images/whiteLogo.svg';
import CustomInput from '../../shared/CustomInput';
import { useViewport } from '../../utils';
import { auth } from '../../firebase';
import { sendPasswordResetEmail } from 'firebase/auth';


const ResetPassword = () => {
    const { isMobile } = useViewport();
    const {
        register,
        handleSubmit,
        formState: { isSubmitting },
    } = useForm({
        mode: 'onChange',
    });
    const toast = useToast();

    const onSubmit = async (formData) => {
        if (formData.email) {
            await sendPasswordResetEmail(auth, formData.email);
            toast({ title: 'Reset password sent to your email', status: 'success' })
        } else {
            toast({ title: 'Need to provide and email to send the reset link', status: 'warning' })
        }
    };

    const onSubmitError = (error) => {
        console.log('An error has occured', error);
    };

    return (
        <Container
            w="100%"
            h={'100vh'}
            maxW="initial"
            p={[6, 6, 6, 10]}
            backgroundColor="white"
            backgroundImage={bg2}
            backgroundRepeat="no-repeat"
            backgroundSize="cover"
            position="relative"
        >
            {isMobile && (
                <Image
                    w={100}
                    src={WhiteLogo}
                    top={10}
                    left={10}
                    marginBottom={5}
                />
            )}
            <Center h={['90%', '90%', '90%', '100%']} mb={[5, 5, 5, 5]}>
                {!isMobile && (
                    <Image
                        w={183}
                        src={WhiteLogo}
                        position="absolute"
                        top={10}
                        left={10}
                    />
                )}
                <VStack
                    w={['90%', '90%', '70%', '30%']}
                    h={['75%', '50%', '50%', '70%']}
                    p={[7, 7, 7, 10]}
                    backgroundColor="white"
                >
                    <form onSubmit={handleSubmit(onSubmit, onSubmitError)}>
                        <VStack alignItems="center" w="100%">
                            <Box pb={[8, 8, 8, 8]} pt={[7, 7, 7, 7]}>
                                <Text
                                    fontWeight={700}
                                    fontSize={[
                                        'x-large',
                                        'x-large',
                                        'x-large',
                                        'xx-large',
                                    ]}
                                    color="brand.green"
                                    justifySelf="center"
                                    align="center"
                                >
                                    Reset your password
                                </Text>
                                <Text
                                    color="brand.green"
                                    fontSize="medium"
                                    align="center"
                                    p={[2, 2, 2, 2]}
                                >
                                    Enter your email adress, and we will send
                                    instructions to reset your password
                                </Text>
                            </Box>
                            <FormControl>
                                <CustomInput
                                    name="email"
                                    register={register}
                                    placeholder="Email Address"
                                    pl={[4, 4, 4, 4]}
                                    borderRadius="2px"
                                    borderColor="lightGray"
                                    h={['60px', '60px', '60px', '60px']}
                                    fontSize={[
                                        'medium',
                                        'medium',
                                        'medium',
                                        'medium',
                                    ]}
                                />
                            </FormControl>
                            <Button
                                w="100%"
                                type="submit"
                                textTransform="initial"
                                my={[4, 4, 4, 4]}
                                p={[2, 2, 2, 2]}
                                borderRadius="2px"
                                alignSelf="stretch"
                                size="lg"
                                fontSize={['large', 'large', 'large', 'large']}
                                h={['60px', '60px', '60px', '60px']}
                                isLoading={isSubmitting}
                            >
                                Send password reset email
                            </Button>
                        </VStack>
                    </form>
                </VStack>
            </Center>
        </Container>
    );
};

export { ResetPassword };