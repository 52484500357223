import React from 'react';
import {
    Box,
    Flex,
    Text,
    Button,
    useColorModeValue,
    useToast,
    Image,
    useClipboard
} from '@chakra-ui/react';
import { format } from 'date-fns';

import expiry from '../../../../images/expiry.svg';
import copy from '../../../../images/Copy.svg';

// Coupon structure:
// {
//     reward: 'Free Coffee',
//     code: 'REDDOOR123',
//     expiry: 'OCT 31',
// }

const Coupon = ({ code, expirationDate, reward }) => {
    const bgColor = useColorModeValue('white', 'gray.800');
    const textColor = useColorModeValue('black', 'white');
    const { onCopy } = useClipboard(code);

    const toast = useToast();

    if (!reward) return null; // Prevent rendering if reward is not provided

    const formattedExpirationDate = expirationDate
        ? format(new Date(expirationDate), 'MMM dd').toUpperCase()
        : 'MM dd';

    return (
        <Box
            width={['100%', '80%', '370px']}
            height="auto" 
            bg={bgColor}
            borderRadius="md"
            borderStyle="dashed"
            borderWidth="2px"
            borderColor="gray.300"
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            p={1}
            mx="auto"
            id="coupon-container"
        >
            {/* Left Side */}
            <Flex
                alignItems="center"
                justifyContent={'start'}
                width={['45%', '45%', '35%', '35%']}
                id="coupon-expiry-container"
            >
                <Box
                    bg="red.500"
                    color="white"
                    borderRadius="sm"
                    px={1}
                    display="flex"
                    flexDirection="column"
                    alignItems="start"
                    mr={4}
                    flex="1"
                    id="coupon-expiry-box"
                >
                    <Flex alignItems="center" mt={2}>
                        <img src={expiry} />
                        <Flex alignItems="center" flexDirection="column">
                            <Text ml={2}>Expires:</Text>
                            <Text fontSize={['sm', 'md']} fontWeight="bold">
                                {formattedExpirationDate}
                            </Text>
                        </Flex>
                    </Flex>
                </Box>
            </Flex>

            <Flex
                flexDirection="column"
                alignItems="center"
            >
                <Text
                    fontSize={['md', 'lg']}
                    fontWeight="bold"
                    color={textColor}
                >
                    {reward}
                </Text>

                <Button
                    size="sm"
                    mr={2}
                    variant="outline"
                    onClick={() => {
                        onCopy();
                        toast({
                            title: 'Code copied to clipboard.',
                            description: code,
                            status: 'info',
                            duration: 9000,
                            isClosable: true,
                        });
                    }}
                >
                    {code} <Image ml={2} src={copy} />
                </Button>
            </Flex>
        </Box>
    );
};

export default Coupon;
