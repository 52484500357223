import React, { useState } from 'react';
import {
    Box,
    Flex,
    Text,
    Button,
    useColorModeValue,
    useClipboard,
    useToast,
} from '@chakra-ui/react';
import { CheckCircleIcon } from '@chakra-ui/icons';
import { motion } from 'framer-motion';
import { format } from 'date-fns';

import expiry from '../../../../images/expiry.svg';

const Coupon = ({ code, expirationDate }) => {
    const bgColor = useColorModeValue('white', 'gray.800');
    const textColor = useColorModeValue('black', 'white');
    const toast = useToast();

    const { onCopy } = useClipboard(code);
    const [isCopied, setIsCopied] = useState(false);

    const handleCopy = () => {
        onCopy();
        setIsCopied(true);
        toast({
            title: 'Code copied to clipboard.',
            description: code,
            status: 'info',
            duration: 9000,
            isClosable: true,
        });
        setTimeout(() => setIsCopied(false), 2000); // Reset after 2 seconds
    };
    const formattedExpirationDate = expirationDate
        ? format(new Date(expirationDate), 'MMM dd').toUpperCase()
        : 'MM dd';

    return (
        <Box
            width={['100%', '80%', '500px']} // Responsive width
            height={['100%', '80%', '130px']} // Allow height to adjust
            bg={bgColor}
            borderRadius="md"
            borderStyle="dashed"
            borderWidth="2px"
            borderColor="gray.300"
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            p={3}
            mx="auto" // Center horizontally
            my={4} // Add some margin for spacing
            id="coupon-container"
        >
            {/* Left Side */}
            <Flex
                alignItems="start"
                justifyContent={'start'}

                flexDirection="column"
                id="coupon-expiry-container"
                h="100%"
                width={['41%', '43%', '45%', '45%']}
                bg="red.500"
                color="white"
                borderRadius="sm"
                p={3}
            >

                <Text fontSize="2xl" fontWeight="bold">
                    COUPON
                </Text>
                <Flex alignItems="center" justifyContent="start">
                    <img src={expiry} />
                    <Flex
                        alignItems="start"
                        flexDirection="column"
                        ml={3}
                        justifyContent="center"
                    >
                        <Text fontWeight="bold">Expires:</Text>
                        <Text fontSize={['sm', 'md']}>
                            {formattedExpirationDate}
                        </Text>
                    </Flex>
                </Flex>
            </Flex>

            <Flex flexDirection="column" alignItems="center">
                <Text
                    fontSize={['md', 'lg']}
                    fontWeight="bold"
                    color={textColor}
                >
                    Promo Code
                </Text>
                <Text
                    fontSize={['lg', 'xl']}
                    fontWeight="bold"
                    color="brand.green"
                >
                    {code}
                </Text>{' '}
                <Button
                    colorScheme={isCopied ? 'green' : 'red'} // Change color on copy
                    size="sm"
                    mt={2}
                    onClick={handleCopy}
                    _hover={{ bg: isCopied ? 'green.600' : 'red.600' }} // Hover state
                    leftIcon={isCopied ? <CheckCircleIcon /> : null} // Add check icon when copied
                    as={motion.button} // Use framer-motion for animation
                    whileTap={{ scale: 0.95 }} // Add a tap animation
                >
                    {isCopied ? 'Copied!' : 'Copy'}
                </Button>
            </Flex>
        </Box>
    );
};

export default Coupon;
