import { Container } from '@chakra-ui/react';
import React, { useContext, useRef } from 'react';
import Badges from '../../Badges';
import { SectionTitle } from '../../components';
import ActivityHistory from '../../components/ActivityHistory/ActivityHistory';
import Rewards from '../../components/Rewards/Rewards';
import Header from '../../Header';
import customerQueries from '../../hooks/queries/customer';
import Slider from '../../Slider';
import { UserContext } from '../../UserContext';
import WelcomeBanner from '../../WelcomeBanner';

function Home() {
    const { user } = useContext(UserContext);
    const bannerRef = useRef(null);
    const { data: customerActivities, refetch } =
        customerQueries.useActivityHistory(user?.a_id ?? '');

    return (
        <Container
            w="100%"
            maxW="initial"
            p={0}
            backgroundColor="white"
            overflowX={['hidden', 'hidden', 'initial', 'initial']}
        >
            <Header user={user} />
            <WelcomeBanner user={user} ref={bannerRef} />
            <SectionTitle
                title="Deals & Ways to Earn"
                mobileTitle="SPECIAL DEALS FOR YOU"
                mobileDescription="Enjoy discounts, exclusive promotions, and personalized deals."
                description="Enjoy special discounts, limited-time promotions, and personalized deals tailored just for you. Accumulate points to redeem for even more savings and rewards."
            />
            <Slider user={user} />
            <Badges user={user} />
            <SectionTitle
                title="All Rewards"
                mobileTitle="ALL REWARDS"
                mobileDescription="Earn points and badges to unlock discounts, gifts, and VIP perks."
                description="Earn points and badges to unlock exclusive discounts, gifts, and VIP perks. Start earning today and enjoy special rewards!"
            />
            <Rewards user={user} refreshActivities={refetch} />
            <SectionTitle
                title="Activity history"
                mobileTitle="ACTIVITY HISTORY"
                description="Your activity history is listed as individual items, starting with the most recent."
                mobileDescription="Your activity history is displayed with the most recent items first."
            />
            <ActivityHistory
                user={user}
                customerActivities={customerActivities}
            />
        </Container>
    );
}

export default Home;
