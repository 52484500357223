import { CloseIcon, InfoOutlineIcon } from '@chakra-ui/icons';
import {
    Box,
    Button,
    Card,
    CardBody,
    CardHeader,
    Flex,
    HStack,
    IconButton,
    Image,
    Modal,
    ModalContent,
    ModalOverlay,
    Progress,
    Spacer,
    Text,
    useDisclosure,
    useToast,
    VStack,
    useClipboard,
} from '@chakra-ui/react';
import { useQueryClient } from '@tanstack/react-query';
import { useEffect, useMemo, useState } from 'react';
import customerQueries from '../../../hooks/queries/customer';
import rewardsQueries from '../../../hooks/queries/rewards';
import copy from '../../../images/Copy.svg';
import likebutton from '../../../images/likebutton.svg';
import redCheckMark from '../../../images/RedCheckmark.svg';
import coin from '../../../images/standing_coin_front.svg';
import { API_KEY, useViewport } from '../../../utils';

//Components
import InfoModal from './InfoModal/InfoModal';
import RewardButtons from './RewardButtons/RewardButtons';

const getRewardCoupons = (rewardPool, coupons) => {
    //get all the coupons which have the pool mathcing the reward pool and map to the coupon code
    return coupons?.data
        ?.filter((coupon) => coupon.pool === rewardPool)
        .map((coupon) => ({
            code: coupon.code,
            expirationDate: coupon.expires_at,
        }));
};

function RewardItem({
    id,
    image,
    title,
    points,
    details,
    user,
    spendablePoints,
    couponPool,
    status,
    refreshActivities,
    refreshCustomerData,
    refreshRewards,
}) {
    const [isRewardFavorite, setIsRewardFavorite] = useState(false);
    const { isMobile } = useViewport();
    const toast = useToast();
    const {
        isOpen: isClaimModalOpen,
        onOpen: onClaimModalOpen,
        onClose: onClaimModalClose,
    } = useDisclosure();
    const [codes, setCodes] = useState([]);
    const queryClient = useQueryClient();

    const { data: couponsData, refetch: refetchCoupons } =
        customerQueries.useCoupons(user?.a_id ?? '', {
            enabled: false,
        });

    const rewardsCoupons = useMemo(() => {
        return getRewardCoupons(couponPool, couponsData);
    }, [couponPool, couponsData]);

    const {
        data: customerData,
        refetch,
        isSuccess,
    } = customerQueries.useCustomer(user?.a_id ?? '', { enabled: false });

    const { mutateAsync: toggleFavoriteReward, isSuccess: toggledFavorite } =
        customerQueries.useToggleFavoriteReward();

    const onToggleFavoriteReward = () => {
        toggleFavoriteReward(
            { userId: user?.a_id, rewardId: id, customerId: user.a_id },
            {
                onSuccess: () => {
                    setIsRewardFavorite(!isRewardFavorite);
                    refetch();
                    refreshRewards();
                    toast({
                        title: isRewardFavorite
                            ? 'Item removed from your favorites!'
                            : 'Item added to your favorites!',
                        duration: 5000,
                        position: isMobile ? 'bottom' : 'bottom-right',
                        isClosable: true,
                        status: 'success',
                        variant: 'custom',
                    });
                },
            }
        );
    };

    useEffect(() => {
        isSuccess &&
            setIsRewardFavorite(
                customerData.properties.favorite_rewards?.find(
                    (favorite) => favorite === id
                )
            );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isSuccess, toggledFavorite, customerData]);

    const { mutate: claimReward } = rewardsQueries.useClaimReward();

    const onRewardClaim = () => {
        claimReward(
            { rewardId: id, customerId: user?.a_id },
            {
                onSuccess: (data) => {
                    refreshActivities();
                    refetchCoupons();
                    refreshCustomerData();
                    setCodes([...codes, data?.coupon?.code]);
                    queryClient.invalidateQueries([
                        API_KEY.activities,
                        user?.a_id,
                    ]);
                    onClaimModalOpen();
                },
            }
        );
    };

    return (
        <>
            <Card
                minWidth={[350, 350, 400, 400]}
                maxWidth={[350, 350, 400, 480]}
                w={['auto', 'auto', 'auto', '100%']}
                minH={[320, 300]}
                h={300}
                maxW={['100%', 800]}
                maxH={800}
                p={2}
                m={1}
                border="1px solid"
                borderColor="gray.100"
                id="reward-card-container"
            >
                <CardHeader position="relative">
                    <InfoModal details={details} />
                    {status !== 'scheduled' && (
                        <Box
                            position="absolute"
                            top={2}
                            right={12}
                            cursor="pointer"
                            opacity={isRewardFavorite ? '' : 0.5}
                            onClick={onToggleFavoriteReward}
                            _hover={{
                                transform: 'scale(1.1)', // Slightly enlarge the icon
                                transition: 'transform 0.2s ease-in-out', // Smooth transition effect
                            }}
                        >
                            <img src={likebutton} alt="like button" />
                        </Box>
                    )}
                </CardHeader>
                <CardBody
                    padding={[0, 0, 0, 0]}
                    display="flex"
                    alignItems="center"
                >
                    <HStack width="100%">
                        <Box w={160} h={210} minW={150} minH={210} id="image">
                            <Image src={image} width="auto" />
                        </Box>
                        <VStack
                            pr={[2, 0]}
                            id="progress-stack"
                            w="60%"
                            gap="3"
                            align="start"
                        >
                            <Text
                                color="brand.green"
                                fontWeight={700}
                                fontSize={['large', 'larger']}
                                id="title"
                            >
                                {title}
                            </Text>
                            <Box w="100%" id="progress-box">
                                <Progress
                                    colorScheme="brand.greens"
                                    size="lg"
                                    value={(spendablePoints * 100) / points}
                                    w="100%"
                                />
                            </Box>
                            <Flex direction="row" width="100%">
                                {status !== 'scheduled' && (
                                    <HStack gap={1}>
                                        <img src={coin} alt="coin" />
                                        <Text
                                            fontSize="medium"
                                            fontWeight={700}
                                            color="brand.green"
                                        >
                                            {points?.toLocaleString()}
                                        </Text>
                                        <Text
                                            fontSize="medium"
                                            fontWeight={700}
                                            color="brand.green"
                                        >
                                            Points
                                        </Text>
                                    </HStack>
                                )}
                                <Spacer />
                            </Flex>
                            <RewardButtons
                                points={points}
                                spendablePoints={spendablePoints}
                                onRewardClaim={onRewardClaim}
                                rewardsCoupons={rewardsCoupons}
                                status={status}
                            />
                        </VStack>
                    </HStack>
                </CardBody>
            </Card>
            <Modal
                size="sm"
                isOpen={isClaimModalOpen}
                onClose={onClaimModalClose}
                isCentered
            >
                <ModalOverlay />
                <ModalContent>
                    <VStack position="relative" textAlign="center" p={10}>
                        <IconButton
                            isRound={true}
                            variant="solid"
                            backgroundColor="gray.200"
                            aria-label="Close"
                            fontSize="16px"
                            size="sm"
                            position="absolute"
                            top={2}
                            right={2}
                            icon={<CloseIcon boxSize={2} color="brand.green" />}
                            onClick={onClaimModalClose}
                        />
                        <Image src={redCheckMark} alt="Success checkmark" />
                        <Text
                            fontSize="medium"
                            fontWeight={700}
                            color="brand.green"
                        >
                            Successfully Claimed!
                        </Text>
                        <Text color="brand.green">
                            You've unlocked a new promo code. Copy the code
                            below and enter it at checkout to enjoy your bonus.
                        </Text>
                        <Flex direction="row">
                            {codes?.map((pCode, i) => (
                                <CopyButton code={pCode} key={i} />
                            ))}
                        </Flex>
                        <Flex
                            visibility="hidden"
                            direction="row"
                            align="center"
                            justify="center"
                        >
                            <InfoOutlineIcon color="brand.red" />
                            <Text color="brand.red" fontWeight="500" ml={2}>
                                Available until: 10/07/2025
                            </Text>
                        </Flex>
                    </VStack>
                </ModalContent>
            </Modal>
        </>
    );
}

const CopyButton = ({ code }) => {
    const toast = useToast();
    const { onCopy } = useClipboard(code);
    return (
    <Button
        size="xs"
        mr={2}
        variant="outline"
        onClick={() => {
            onCopy();
            toast({
                title: 'Code copied to clipboard.',
                description: code,
                status: 'info',
                duration: 9000,
                isClosable: true,
            });
        }}
    >
    {code} <Image ml={2} src={copy} />
</Button>)
}

export default RewardItem;
