import {
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalHeader,
    ModalOverlay,
    SimpleGrid,
    Text,
} from '@chakra-ui/react';
import { useViewport } from '../../../../utils';

import MinimalisticCoupon from '../Coupons/MinimalisticCoupon';

const AllCouponsModal = ({ isOpen, onClose, rewardsCoupons }) => {
    const { isMobile } = useViewport();
    return (
        <Modal
            isOpen={isOpen}
            onClose={onClose}
            isCentered
            size={isMobile ? 'full' : 'xl'}
            maxWidth="fit-content"
            maxHeight="fit-content"
            minH="fit-content"
            minW="1000px"
        >
            <ModalOverlay />
            <ModalContent minWidth="fit-content" height="fit-content">
                <ModalCloseButton
                    borderRadius="full"
                    bg="brand.green"
                    color="white"
                    fontSize="lg"
                    _hover={{
                        bg: 'brand.green',
                        borderColor: 'red.600',
                    }}
                />
                <ModalHeader>
                    <Text fontWeight="bold" fontSize="2xl" color="teal.900">
                        Available promo codes
                    </Text>
                    <Text fontSize="lg" color="gray.600">
                        Redeem a reward to unlock discounts on these eligible
                        items.
                    </Text>
                </ModalHeader>
                <ModalBody>
                    <SimpleGrid
                        columns={[1, 1, 2]} // 1 column for mobile, 2 for tablet/desktop
                        spacing={[2, 3]} // Space between grid items
                        mt={1}
                    >
                        {rewardsCoupons?.map((coupon) => (
                            <MinimalisticCoupon
                                key={coupon.code} // Always use a unique key in map
                                code={coupon.code}
                                expirationDate={coupon.expirationDate}
                                reward={coupon.reward}
                            />
                        ))}
                    </SimpleGrid>
                </ModalBody>
            </ModalContent>
        </Modal>
    );
};

export default AllCouponsModal;
