import { ChevronRightIcon, LockIcon, WarningIcon } from '@chakra-ui/icons';
import {
    Box,
    Button,
    Center,
    Flex,
    Icon,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalHeader,
    ModalOverlay,
    Text,
    Tooltip,
    useDisclosure,
} from '@chakra-ui/react';
import { useViewport } from '../../../../utils';

//Components
import Coupon from '../Coupons/Coupon';

const RewardsButtons = ({
    points = '',
    spendablePoints,
    onRewardClaim,
    rewardsCoupons,
    status,
}) => {
    const {
        isOpen: noRewardsOpen,
        onOpen: onNoRewardsOpen,
        onClose: onNoRewardClose,
    } = useDisclosure({ id: 'noRewards' });

    const {
        isOpen: couponsOpen,
        onOpen: onCouponsOpen,
        onClose: onCouponsClose,
    } = useDisclosure({ id: 'coupons' });
    const pointsWithoutComa = points.replace(',', '');
    const canBeClaimed = Number(pointsWithoutComa) <= Number(spendablePoints);
    const { isMobile } = useViewport();

    const handleClaim = () => {
        if (canBeClaimed && status !== 'scheduled') {
            onRewardClaim();
        } else if (isMobile && status !== 'scheduled') {
            onNoRewardsOpen();
        }
    };

    const handleCodesClick = () => {
        onCouponsOpen();
    };
    return (
        <>
            {/* Tooltip for desktop users */}
            <Flex gap={1} flexDirection={['column', 'column', 'column', 'row']}>
                {!isMobile && (
                    <Tooltip
                        label={
                            !canBeClaimed
                                ? `You need ${
                                      Number(pointsWithoutComa) -
                                      spendablePoints
                                  } more points to claim this reward.`
                                : ''
                        }
                        shouldWrapChildren
                        hasArrow
                        bg="brand.green"
                        opacity={0.6}
                        color="white"
                        placement="top"
                    >
                        <Button
                            alignSelf="start"
                            size="sm"
                            fontSize="small"
                            onClick={handleClaim}
                            color="white"
                            _hover={{
                                bg: 'brand.green',
                                transform: 'scale(1.05)',
                                boxShadow: 'lg',
                            }}
                            _active={{
                                transform: 'scale(0.98)',
                            }}
                            _disabled={{
                                bg: 'brand.green',
                                cursor: 'not-allowed',
                                opacity: 0.6,
                                boxShadow: 'none',
                            }}
                            isDisabled={!canBeClaimed || status === 'scheduled'}
                        >
                            {'CLAIM REWARD '}
                            {canBeClaimed ? (
                                <ChevronRightIcon
                                    mt="2px"
                                    boxSize={18}
                                    color="white"
                                />
                            ) : (
                                <LockIcon ml={2} color="white" />
                            )}
                        </Button>
                    </Tooltip>
                )}

                {/* Button for mobile users */}
                {isMobile && (
                    <Button
                        alignSelf="start"
                        size="sm"
                        fontSize="small"
                        onClick={handleClaim}
                        color="white"
                        _hover={{
                            bg:
                                canBeClaimed && status !== 'scheduled'
                                    ? 'brand.red'
                                    : 'brand.green',
                            transform:
                                canBeClaimed && status !== 'scheduled'
                                    ? 'scale(1.05)'
                                    : 'none',
                            boxShadow:
                                canBeClaimed && status !== 'scheduled'
                                    ? 'lg'
                                    : 'none',
                        }}
                        _active={{
                            transform: 'scale(0.98)',
                        }}
                        bg={
                            canBeClaimed && status !== 'scheduled'
                                ? 'brand.red'
                                : 'brand.green'
                        } // Disable color
                        cursor={
                            canBeClaimed && status !== 'scheduled'
                                ? 'pointer'
                                : 'not-allowed'
                        } // Change cursor
                        opacity={
                            canBeClaimed && status !== 'scheduled' ? 1 : 0.6
                        } // Reduce opacity if disabled
                    >
                        {'CLAIM REWARD '}
                        {canBeClaimed && !status === 'scheduled' ? (
                            <ChevronRightIcon
                                mt="2px"
                                boxSize={18}
                                color="white"
                            />
                        ) : (
                            <LockIcon ml={2} color="white" />
                        )}
                    </Button>
                )}
                <Tooltip
                    label={
                        !rewardsCoupons?.length
                            ? `You don't have any promo codes to claim.`
                            : ''
                    }
                    shouldWrapChildren
                    hasArrow
                    bg="brand.green"
                    opacity={0.6}
                    color="white"
                    placement="top"
                >
                    <Button
                        alignSelf="start"
                        size="sm"
                        fontSize="small"
                        onClick={handleCodesClick}
                        color="white"
                        bg="brand.green"
                        _hover={{
                            bg: 'greens.600',
                            transform: 'scale(1.05)',
                            boxShadow: 'lg',
                        }}
                        _active={{
                            transform: 'scale(0.98)',
                        }}
                        _disabled={{
                            bg: 'brand.green',
                            cursor: 'not-allowed',
                            opacity: 0.6,
                            boxShadow: 'none',
                        }}
                        isDisabled={!rewardsCoupons?.length}
                    >
                        {'CODES'}
                        {rewardsCoupons?.length ? (
                            <ChevronRightIcon
                                mt="2px"
                                boxSize={18}
                                color="white"
                            />
                        ) : (
                            <LockIcon ml={2} color="white" />
                        )}
                    </Button>
                </Tooltip>
            </Flex>

            {/* Modal for mobile users */}
            <Modal
                isOpen={noRewardsOpen}
                onClose={onNoRewardClose}
                isCentered
                size="full"
            >
                <ModalOverlay />
                <ModalContent>
                    <ModalCloseButton
                        borderRadius="full"
                        bg="brand.green"
                        color="white"
                        fontSize="lg"
                        _hover={{
                            bg: 'brand.green',
                            borderColor: 'red.600',
                        }}
                    />
                    <ModalHeader />
                    <ModalBody>
                        <Center flexDirection="column">
                            <Icon
                                as={WarningIcon}
                                boxSize={20}
                                color="brand.red"
                                mb={4}
                            />
                            <Text
                                fontSize="lg"
                                fontWeight="bold"
                                textAlign="center"
                                mb={4}
                            >
                                Oops! You don't have enough points to claim this
                                reward.
                            </Text>
                            <Text fontSize="2xl" textAlign="center">
                                You need{' '}
                                <Box
                                    as="span"
                                    fontWeight="bold"
                                    color="red.500"
                                >
                                    {Number(pointsWithoutComa) -
                                        spendablePoints}
                                </Box>{' '}
                                more points to claim this reward.
                            </Text>
                        </Center>
                    </ModalBody>
                </ModalContent>
            </Modal>
            <Modal
                isOpen={couponsOpen}
                onClose={onCouponsClose}
                isCentered
                size={isMobile ? 'full' : 'xl'}
            >
                <ModalOverlay />
                <ModalContent>
                    <ModalCloseButton
                        borderRadius="full"
                        bg="brand.green"
                        color="white"
                        fontSize="lg"
                        _hover={{
                            bg: 'brand.green',
                            borderColor: 'red.600',
                        }}
                    />
                    <ModalHeader />
                    <ModalBody>
                        <Center flexDirection="column">
                            {rewardsCoupons?.map((coupon) => (
                                <Coupon
                                    code={coupon.code}
                                    expirationDate={coupon.expirationDate}
                                    key={coupon.code}
                                />
                            ))}
                        </Center>
                    </ModalBody>
                </ModalContent>
            </Modal>
        </>
    );
};

export default RewardsButtons;
